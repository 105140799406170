import React, { useEffect, useState, useRef } from 'react'
// import { graphql } from 'gatsby'
import {
  ChakraProvider,
  extendTheme,
  Heading,
  Box,
  Flex,
  SimpleGrid,
  FormControl,
  Checkbox,
  RadioGroup,
  Radio,
  Input,
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
// import PhoneNumberInput from '../utils/usePhoneNumberFormat'
import axios from "axios"
import Layout from '../components/Layout'
import pageTheme from '../gatsby-plugin-chakra-ui/theme'
import SEO from '../components/SEO'
import '../assets/slick/slick.scss'
import useGetPlansByID from '../utils/useGetPlansByID'
import useGetAddonsByID from '../utils/useGetAddonsByID'
import themeVars from '../gatsby-plugin-chakra-ui/theme-vars'
import MTALogo from '../assets/svg/mta_logo.inline.svg'
import BroadbandLabel from '../components/Blocks/Alopex/BroadbandLabel/BroadbandLabel'

const theme = extendTheme({ ...pageTheme })

const PageShare = ({ location }) => {
  const [labelId, setLabelId] = useState(null)
  const refInitialFocus = useRef(null)
  const [isCustomer, setIsCustomer] = useState(null)
  const [isInvalid, setIsInvalid] = useState(false)

  const [install, setInstall] = useState(65.00)
  const [twInstall, setTWInstall] = useState(0)
  const [otFee, setOTFee] = useState(65.00)

  const [modem, setModem] = useState(1.99)
  const [baseDue, setBaseDue] = useState(0)
  const [mDue, setMDue] = useState(0)

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    let phone = form.querySelector('#phone-number').value
    const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/g
    if (phone.match(phoneRegex) !== null) {
      setIsInvalid(false)
      axios({
        method: "post",
        url: "https://getform.io/f/akkgvela",
        data: new FormData(form)
      })
      .then(r => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
    } else {
      setIsInvalid(true)
    }
  }

  // Get order details from URL
  let planID = ''
  let addonIDs = ''
  let address = ''
  let address1 = ''
  let address2 = ''
  let city = ''
  let state = ''
  let zip = ''
  let fname = ''
  let lname = ''
  let email = ''
  let phone = ''
  
  if (location.search !== '') {
    const params = new URLSearchParams(location.search)
    // Plan
    planID = params.get("planID")
    
    // Add-ons
    addonIDs = params.get("addonIDs")

    // Service Address
    address = params.get('address')
    address1 = params.get('addressl1')
    address2 = params.get('addressl2')
    city = params.get('city')
    state = params.get('state')
    zip = params.get('zip')

    // Total Due Monthly
    // mdue = params.get('mdue')

    // Customer info
    fname = params.get('fname')
    lname = params.get('lname')
    email = params.get('email')
    phone = params.get('phone')
  }

  // Get Plan
  const [plan, setPlan] = useState(useGetPlansByID(planID))
  
  // Get Addons
  const [addons, setAddons] = useState(useGetAddonsByID(addonIDs.split(',')))

  // Calculate fees
  useEffect(() => {
    // console.log('Plan: ', plan)
    // console.log('Addons: ', addons)
    if (typeof addons !== 'null' && typeof plan !== 'null') {
      // Has totalWiFi, adjust fees
      if (addons.some(a => a.id == 117319)) {
        setInstall(65.00)
        setTWInstall(35.00)
        setOTFee(100.00)
        setModem(0.00)
        // console.log('install', install, 'twInstall', twInstall, 'otFee', otFee, 'modem', modem)
      }
      // Get base (addons + plan prices) and total monthly due (with or w/out 1.99 modem rental)
      let addonsPrice = 0
      console.log('addons length', addons.length)
      if (addons.length > 0) {
        addonsPrice = addons.reduce((a, c) => {
            return a + parseFloat(c.price)
        }, 0)
      }
      setBaseDue( addonsPrice + parseFloat(plan[0]?.planOptions[0]?.ptplanPrice) )
    }
  }, [plan, addons])

  useEffect(() => {
    setMDue(baseDue + modem)
  }, [baseDue, modem])

  // Get BB Label
  useEffect(() => {
    if (typeof plan !== 'null') {
      setLabelId(plan[0].labelId)
    }
  }, [plan])
  
 
  return (
    <ChakraProvider theme={theme}>
      <Layout
        heroContent={{
          heroSlides: [{
              heroSlideIllustrationSelect: 'home',
              heroSlideContentH1: '<strong>Your Plan</strong>, Your Terms',
              heroSlideContentH4: 'Endless browsing, streaming, and gaming. No gimmicks. No throttling. No hidden fees.',
              heroSlideLink: null,
              secondaryButton: null,
              teritaryButton: null,
          }]
        }}
      >
          <SEO title="Share Internet Plan" noIndex />
          <Box mb="150px" className="container printGrid" maxWidth="1440px">
            <Box>
              <Box
                className="mtaOrderLogo"
                display="none"
                maxW="80px"
                mb="20px"
                sx={{
                  svg: {
                    fill: themeVars.colors.blue
                  }
                }}
              >
                <MTALogo />
              </Box>
              <Flex justify="space-between" align="center">
                <Heading className="summaryH1" as="h2">Internet Order Summary</Heading>
                <Flex className="printButton" flexDir="column" alignItems="center" gridGap="5px" fontSize="smaller" cursor="pointer" onClick={() => window.print()}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" style={{maxWidth: '30px', width: '100%', color: themeVars.colors.blue}}>
                    <rect x="2.34" y="13.09" width="45.33" height="23.82" rx="3.76" ry="3.76" fill="currentColor"/>
                    <circle cx="39.36" cy="17.88" r="1.6" fill="#fff"/>
                    <circle cx="44.06" cy="17.88" r="1.6" fill="#fff"/>
                    <path d="M39.36 13.09V1.54H16.74l-6.11 6.11v5.44M10.64 29.93h28.73v18.51H10.64z" fill="#fff" stroke="currentColor" strokeLinejoin="round" strokeWidth="2"/>
                    <path stroke="currentColor" fill="currentColor" strokeLinejoin="round" strokeWidth="2" d="M16.75 1.54v6.11h-6.11"/>
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.41 43.75h19.18M15.41 39.05h16.1M15.41 34.33h7.96"/>
                  </svg>
                  Print
                </Flex>
              </Flex>

              <Heading as="h4">Customer Information</Heading>
              <Box className="detailWrap" mb="20px">
                <Flex gridGap="10px" mb="10px" flexWrap="wrap">
                  <Flex className="detailBox" justify="space-between" p="12px 15px" rounded="10px" border={`1px solid ${themeVars.colors.blue}`} flex="1 1">
                    <Heading as="h6" mb="0!important"><strong>First</strong></Heading>
                    <Heading as="h6" mb="0!important">{fname}</Heading>
                  </Flex>
                  <Flex className="detailBox" justify="space-between" p="12px 15px" rounded="10px" border={`1px solid ${themeVars.colors.blue}`} flex="1 1">
                    <Heading as="h6" mb="0!important"><strong>Last</strong></Heading>
                    <Heading as="h6" mb="0!important">{lname}</Heading>
                  </Flex>
                </Flex>
                <Flex gridGap="10px" mb="10px" flexWrap="wrap">
                  <Flex className="detailBox" justify="space-between" p="12px 15px" rounded="10px" border={`1px solid ${themeVars.colors.blue}`} flex="1 1">
                    <Heading as="h6" mb="0!important"><strong>Email</strong></Heading>
                    <Heading as="h6" mb="0!important">{email}</Heading>
                  </Flex>
                  <Flex className="detailBox" justify="space-between" p="12px 15px" rounded="10px" border={`1px solid ${themeVars.colors.blue}`} flex="1 1">
                    <Heading as="h6" mb="0!important"><strong>Phone</strong></Heading>
                    <Heading as="h6" mb="0!important">{phone}</Heading>
                  </Flex>
                </Flex>
                <Flex className="detailBox" justify="space-between" p="12px 15px" rounded="10px" border={`1px solid ${themeVars.colors.blue}`}>
                  <Heading as="h6" mb="0!important"><strong>Service Address</strong></Heading>
                  <Heading as="h6" mb="0!important">{address}</Heading>
                </Flex>
              </Box>
              
              <Heading as="h4">Plan</Heading>
              <Box className="detailWrap" mb="20px">
                {plan.length ? (
                  <Flex className="detailBox" justify="space-between" p="12px 15px" rounded="10px" border={`1px solid ${themeVars.colors.blue}`}>
                    <Heading as="h6" mb="0!important"><strong>{plan[0]?.planOptions[0]?.ptplanName}</strong></Heading>
                    <Heading as="h6" mb="0!important">${plan[0]?.planOptions[0]?.ptplanPrice}</Heading>
                  </Flex>
                ) : (
                  <Heading as="h6">No plan selected.</Heading>
                )}
              </Box>
              
              <Heading as="h4">Add-ons</Heading>
              <Box className="detailWrap" mb="20px">
                {addons.length > 0 ? (
                  <>
                    {addons.map((addon, i) => {
                      return (
                      <Flex className="detailBox" justify="space-between" p="12px 15px" rounded="10px" border={`1px solid ${themeVars.colors.blue}`} mb="10px">
                        <Heading as="h6" mb="0!important" dangerouslySetInnerHTML={{ __html: addon?.addonTitle}}/>
                        <Heading as="h6" mb="0!important">${addon?.price}</Heading>
                      </Flex>
                      )
                    })}
                  </>
                ) : (
                  <Heading as="h6">No addons selected.</Heading>
                )}
              </Box>
              
              <Heading as="h4">One-Time Fee(s)</Heading>
              <Box className="detailWrap" mb="50px">
                <Flex className="detailBoxSm" justify="space-between" p="6px 15px" mb="5px">
                  <Box>Installation</Box>
                  <Box borderBottom="1px dashed" flex="1" opacity="0.4" mb="9px"></Box>
                  <Box>${install.toFixed(2)}</Box>
                </Flex>
                {twInstall > 0 && (
                  <Flex className="detailBoxSm" justify="space-between" p="6px 15px" mb="5px">
                    <Box>totalWiFi&#8482; Installation</Box>
                    <Box borderBottom="1px dashed" flex="1" opacity="0.4" mb="9px"></Box>
                    <Box>${twInstall.toFixed(2)}</Box>
                  </Flex>
                )}
                <Flex className="detailBox" justify="space-between" p="12px 15px" rounded="10px" border={`1px solid ${themeVars.colors.blue}`}>
                  <Heading as="h6" mb="0!important"><strong>Total</strong></Heading>
                  <Heading as="h6" mb="0!important">${otFee.toFixed(2)}</Heading>
                </Flex>
              </Box>

              <Heading as="h4">Monthly</Heading>
              <Box className="detailWrap" mb="50px">
                <Flex className="detailBoxSm" justify="space-between" p="6px 15px" mb="5px">
                  <Box>Modem Rental Fee</Box>
                  <Box borderBottom="1px dashed" flex="1" opacity="0.4" mb="9px"></Box>
                  <Box>${modem.toFixed(2)}</Box>
                </Flex>
                <Flex className="detailBoxSm" justify="space-between" p="6px 15px" mb="5px">
                  <Box>Service &amp; Addons</Box>
                  <Box borderBottom="1px dashed" flex="1" opacity="0.4" mb="9px"></Box>
                  <Box>${baseDue.toFixed(2)}</Box>
                </Flex>
                <Flex className="detailBox" justify="space-between" p="12px 15px" rounded="10px" border={`1px solid ${themeVars.colors.blue}`}>
                  <Heading as="h6" mb="0!important"><strong>Due Monthly</strong></Heading>
                  <Heading as="h6" mb="0!important">${mDue.toFixed(2)}</Heading>
                </Flex>
              </Box>

            </Box>

            <Box className="boxSep" as="hr" mt="40px" pb="40px" />

            <Box display="grid" gridTemplateColumns={['1fr', '1fr 1fr']} gap="50px">
              <Box
                border={`1px solid ${themeVars.colors.blue}`}
                p="20px"
                className="labelWrapper"
                rounded="10px"
                sx={{
                  '.bcl_link': {
                    color: themeVars.colors.blue,
                    textDecor: 'underline'
                  },
                  '.bcl_widget-container': {
                    border: 'none',
                    margin: 0,
                    padding: 0,
                    color: themeVars.colors.blue,
                    '.bcl_title': {
                      '.bcl_h1': {
                        fontSize: '1.333333333rem'
                      },
                      '&::after': {
                        background: themeVars.colors.blue,
                        borderRadius: '999px',
                        height: '0.5em'
                      }
                    },
                    '.bcl_provider, .support': {
                      '&::after': {
                        background: themeVars.colors.blue,
                        borderRadius: '999px',
                        height: '0.3em'
                      }
                    },
                    '.monthly, .clear-space, .discounts': {
                      '&::after': {
                        background: themeVars.colors.blue,
                        borderRadius: '999px',
                        height: '0.2em'
                      }
                    }
                  }
                }}
              >
                {labelId !== null && (
                  <BroadbandLabel attributes={{ data: { label_id: labelId } }} />
                )}
              </Box>
              <Box className="orderForm">
                <Heading as="h2">Ready to order?</Heading>
                <form method="post" onSubmit={handleOnSubmit}>
                  <Box mb="20px">
                    <RadioGroup my={['10px']} name="Existing MTA Customer?" value={isCustomer}>
                      <Box as="p" mb={0}>
                        Are you an existing MTA customer?
                      </Box>
                      <Stack direction="row">
                        <Radio value={true} colorScheme="mint" isRequired onChange={() => setIsCustomer(true)}>Yes</Radio>
                        <Radio value={false} colorScheme="purple" isRequired onChange={() => setIsCustomer(false)}>No</Radio>
                      </Stack>
                    </RadioGroup>
                  </Box>

                  <SimpleGrid columns={[1, 2]} spacing="20px" mb="20px">

                    <FormControl id="first-name" isRequired>
                      <Input name="First Name" placeholder="First Name" ref={refInitialFocus} defaultValue={fname} />
                    </FormControl>

                    <FormControl id="last-name" isRequired>
                      {/* <FormLabel>Last name</FormLabel> */}
                      <Input name="Last Name" placeholder="Last Name" defaultValue={lname} />
                    </FormControl>

                    <FormControl id="email" isRequired>
                      <Input name="Email" type="email" placeholder="Email" defaultValue={email} />
                    </FormControl>

                    <FormControl id="phone-number" isRequired>
                      <Input name="phone" type="tel" placeholder="Phone" defaultValue={phone} />
                    </FormControl>

                  </SimpleGrid>

                  <FormControl id="addressFormatted" isRequired>
                    <Input name="address" placeholder="Address" defaultValue={address} mb="5px" readOnly="readonly" opacity="0.65" />
                  </FormControl>

                  <Box display="block" mb="20px" textAlign="right" fontSize="small">
                    <Box as="a" href="/residential/internet" color={themeVars.colors.blue} textDecor="underline">Start again</Box> to edit address.
                  </Box>

                  {/* <Checkbox name="Marketing Optin" value="Yes" mb="20px">
                    I would like to receive marketing communications from MTA.
                  </Checkbox> */}

                  <Input type="hidden" name="Address1" value={address1} />
                  <Input type="hidden" name="Address2" value={address2} />
                  <Input type="hidden" name="City" value={city} />
                  <Input type="hidden" name="State" value={state} />
                  <Input type="hidden" name="Zip" value={zip} />

                  <Input type="hidden" name="Plan" value={plan[0]?.planOptions[0]?.ptplanName} />
                  <Input type="hidden" name="Add-ons" value={ addons.map((a,i) => { return `${a.title} (${a.price})` }) } />
                  <Input type="hidden" name="Monthly Total" value={mDue.toFixed(2)} />

                  {/* SUBMIT / SUCCESS / ERROR */}
                  {serverState.status ? (
                    <Box>
                      {!serverState.status.ok ? (
                        <Alert status="error" mt="20px" borderRadius="10px">
                          <AlertIcon />
                          <AlertTitle mr={2}>Oops! There was an error.</AlertTitle>
                          <AlertDescription>{serverState.status.msg} Please <a href="tel:9077453211">call customer support</a> to complete your order.</AlertDescription>
                        </Alert>
                      ) : (
                        <Alert status="success" mt="20px" colorScheme="mint" variant="solid" borderRadius="10px">
                          <AlertIcon />
                          <AlertTitle mr={2}>Your request has been received!</AlertTitle>
                          <AlertDescription>Thank you for submitting the form. We appreciate your interest and will be in touch with you soon.</AlertDescription>
                        </Alert>
                      )}
                    </Box>
                  ) : (
                    <SimpleGrid columns={[1, 2]}>
                      <Box></Box>
                      <Box>
                        <Input
                          background="auto"
                          className="themeGradient"
                          type="submit"
                          value="Submit"
                          color={themeVars.colors.white}
                          cursor="pointer"
                          disabled={serverState.submitting}
                        />
                      </Box>
                    </SimpleGrid>
                  )}

                </form>
              </Box>
            </Box>
          </Box>
          <style>{`
            .detailBox {
              flex-wrap: wrap;
            }
            @media print {
              .headerContainer, .footer, .printButton, .orderForm {display:none!important;}
              .mtaOrderLogo {display:block!important;}
              .detailWrap {margin-bottom:15px;}
              .detailBox {padding:5px;}
              .detailBoxSm {padding:5px;font-size:14px;margin-bottom:0;}
              .boxSep {display:none;}
              h2.summaryH1 {font-size:1rem!important;font-weight:bold!important;margin-bottom:10px!important;}
              h4 {margin-bottom: 4px!important;}
              h4, h6 {font-size:14px!important;}
              .printGrid {display:grid;grid-template-columns:1fr 1fr;gap:25px;margin-bottom:0!important;}
              .bcl_widget-container {display:block;}
              .bcl_widget-container {font-size:0.7rem;}
              .layout {margin-bottom:0!important;}
            }
          `}</style>
      </Layout>
    </ChakraProvider>
  )
}

// export const query = graphql`
//   query internetPage {
//     plans: allWpPlan(sort: {fields: plan___ptplanOrder}) {
//       nodes {
//         databaseId
//         plan {
//           ptplanOptions {
//             ptplanName
//             ptplanSlug
//             ptplanPrice
//           }
//         }
//       }
//     }

//     addons: allWpPlanAddon(sort: {fields: addon___ptaddonOrder}) {
//       nodes {
//         databaseId
//         title
//         addon {
//           ptaddonPrice
//         }
//       }
//     }
//   }
// `

export default PageShare